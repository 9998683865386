// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.
import { t } from '../i18n/i18n';

export enum ErrorCode {
  UsernameRequired = 'UsernameRequired',
  EmailInvalid = 'EmailInvalid',
  PasswordRequired = 'PasswordRequired',
  InvalidLoginDetails = 'InvalidLoginDetails',
  InvalidEmailVerification = 'InvalidEmailVerification',
  AccountDoesNotExist = 'AccountDoesNotExist',
  MismatchAccountDetails = 'MismatchAccountDetails',
  TooManyRequests = 'TooManyRequests',
  Unknown = 'Unknown',
  MinimumPasswordLength = 'MinimumPasswordLength',
}

/**
 * This is equivalent to:
 * type ErrorCode = 'UsernameRequired' | 'EmailInvalid' | 'PasswordRequired'
 */
export type ErrorCodeStrings = keyof typeof ErrorCode;

export const ErrorMessage: { [code in ErrorCodeStrings]: () => string } = {
  UsernameRequired: () =>
    t({
      defaultMessage: 'The username field is required.',
      id: 'nimUm5',
    }),
  EmailInvalid: () =>
    t({
      defaultMessage: 'The email provided is not a valid email address.',
      id: 'I+S0Wm',
    }),
  PasswordRequired: () =>
    t({
      defaultMessage: 'The Password field is required.',
      id: 'YKMYgr',
    }),
  InvalidLoginDetails: () =>
    t({
      defaultMessage: 'The email or password provided is incorrect.',
      id: '7bRp+J',
    }),
  InvalidEmailVerification: () =>
    t({
      defaultMessage: 'The email can not be verified.',
      id: 'y5hOCl',
    }),
  AccountDoesNotExist: () =>
    t({
      defaultMessage: 'You do not have an active account in this system. Please contact an administrator.',
      id: 'zJhjPm',
    }),
  MismatchAccountDetails: () =>
    t({
      defaultMessage: 'Login provider account details did not match supplied account details.',
      id: 'QuxyB4',
    }),
  TooManyRequests: () =>
    t({
      defaultMessage:
        'Your account has been locked temporarily due to too many failed login attempts. Please try again later, or if you have forgotten your password, please use the "Forgot password?" link.',
      id: '12dZfV',
    }),
  Unknown: () =>
    t({
      defaultMessage: 'Something went wrong. Please try again.',
      id: 'W/5hwr',
    }),
  MinimumPasswordLength: () =>
    t({
      defaultMessage: 'The password needs at least 8 characters.',
      id: 'EbKGb0',
    }),
};

export const getErrorMessage = (errorCode: ErrorCodeStrings | null): string | null => {
  if (!errorCode) return null;

  if (Object.keys(ErrorMessage).includes(errorCode)) {
    return ErrorMessage[errorCode as ErrorCode]();
  }

  return ErrorMessage['Unknown']();
};

// Get the error code from an error instance
export const getErrorCode = (err: any): ErrorCodeStrings => {
  if (err instanceof Error && Object.keys(ErrorMessage).includes(err.message)) {
    return err.message as ErrorCode;
  } else {
    return ErrorCode.Unknown;
  }
};
