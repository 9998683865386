// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Alert from '@mui/material/Alert';
import { t } from '../../../i18n/i18n';

export const EmailSuccessPage = ({ email }: { email: string }) => (
  <Alert className="success-alert" severity="success">
    {t({
      defaultMessage: 'A recovery link has been sent to',
      id: 'pQG5Mn',
    })}
    &nbsp;
    <strong>{email}</strong>
    <br />
    {t({
      defaultMessage: 'This may take few minutes. Please check also your spam folder.',
      id: 'aYBc7e',
    })}
  </Alert>
);
