// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import React from 'react';
import { Container } from '@mui/material';
import { ErrorCode } from '../../utils/errorUtils';
import { useParams } from 'react-router-dom';
import { AccountDoesNotExist } from './ErrorPages/AccountDoesNotExist';
import { UnknownError } from './ErrorPages/UnknownError';

// Render the error page based on error code extracted from the path
const RenderErrorPage = ({ errorCode }: { errorCode: string | undefined }) => {
  switch (errorCode) {
    case ErrorCode.AccountDoesNotExist:
      return <AccountDoesNotExist />;
    default:
      return <UnknownError />;
  }
};

const ErrorPage: React.FC = () => {
  const { errorCode } = useParams();
  return (
    <Container component="main" maxWidth="xs" style={{ textAlign: 'center', marginTop: '20vh' }}>
      <RenderErrorPage errorCode={errorCode} />
    </Container>
  );
};

export default ErrorPage;
