// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Button from '@mui/material/Button';
import { Link, useSearchParams } from 'react-router-dom';
import { t } from '../../i18n/i18n';

export const ReturnToLoginLink = () => {
  const [searchParams] = useSearchParams();

  return (
    <Link style={{ textDecoration: 'none' }} to={{ pathname: `/`, search: searchParams.toString() }}>
      <Button color={'primary'} variant="text" style={{ textTransform: 'none' }}>
        {t({
          defaultMessage: 'Return to log in',
          id: 'lszf1I',
        })}
      </Button>
    </Link>
  );
};
