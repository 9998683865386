// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { ErrorCode } from './errorUtils';

// Check the response
const checkResponse = async (res: Response) => {
  if (!res.ok) {
    switch (res.status) {
      case 401:
        throw new Error(ErrorCode.InvalidLoginDetails);
      case 429:
        throw new Error(ErrorCode.TooManyRequests);
      default:
        throw new Error(ErrorCode.Unknown);
    }
  }

  const data = await res.json();

  return data;
};

export const fetchGet = async (url: string, data: { headers: HeadersInit }) => {
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...data.headers,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  }).then((res) => checkResponse(res));
};

export const fetchPost = async (url: string, data: { body: BodyInit; headers: HeadersInit }) => {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...data.headers,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: data.body,
  }).then((res) => checkResponse(res));
};

/**
 * Extracts the domain name from a URL, removing any paths, query parameters, or fragments.
 * @param url The full URL from which the domain name should be extracted.
 * @returns The domain name.
 */
export const extractDomain = (url: string): string => {
  try {
    const urlObj = new URL(url);
    return urlObj.origin;
  } catch (error) {
    console.error('Invalid URL provided:', error);
    return url;
  }
};
