// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { changeLanguage, Language } from '../i18n/i18n';
import { DEFAULT_LANGUAGE } from '../i18n/languages';
import ErrorPage from './Error/ErrorPage';
import { LoginForm } from './Login/LoginForm';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';

const initializeLanguage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const language = (searchParams.get('lang') as Language) || DEFAULT_LANGUAGE;
  changeLanguage(language);
};

const Routing: React.FC = () => {
  initializeLanguage();
  const [searchParams] = useSearchParams();

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/error/:errorCode" element={<ErrorPage />} />

      {/* Capture Unknown routes */}
      <Route path="*" element={<Navigate to={{ pathname: '/login', search: searchParams.toString() }} />} />
    </Routes>
  );
};

export default Routing;
