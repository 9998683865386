// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Dialog from '@mui/material/Dialog';
import LoadingPage from '../Common/LoadingPage';
import { ErrorCode, ErrorCodeStrings, getErrorMessage } from '../../utils/errorUtils';
import { ChangeEvent, useState } from 'react';
import { ReturnToLoginLink } from '../Common/ReturnToLoginLink';
import { EmailSuccessPage } from './ForgotPwdPages/EmailSuccessPage';
import { Box, Button, CircularProgress, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { emailFormatValidation } from '../../utils/emailUtils';
import { t } from '../../i18n/i18n';

interface IForgotPwsState {
  email: string;
  errorCode: ErrorCodeStrings | null;
  isLoading: boolean;
  isSending: boolean;
  isSuccess: boolean;
}

export const ForgotPassword = () => {
  const [forgotPwdState, setForgotPwdState] = useState<IForgotPwsState>({
    email: '',
    errorCode: null,
    isLoading: false, // Indicates whether the page is loading
    isSending: false, // Indicates whether the email is currently being sent
    isSuccess: false, // Indicates whether the email was sent successfully
  });

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForgotPwdState({ ...forgotPwdState, email: event.target.value });
  };

  const onEnter = () => {
    setForgotPwdState({ ...forgotPwdState, errorCode: null, isSending: true });

    if (!emailFormatValidation.test(forgotPwdState.email)) {
      setForgotPwdState({ ...forgotPwdState, errorCode: ErrorCode.EmailInvalid, isSending: false });
      return;
    }
    setForgotPwdState({ ...forgotPwdState, errorCode: null, isSending: false, isSuccess: true });
  };

  return (
    <Dialog open>
      {forgotPwdState.isLoading ? (
        <LoadingPage />
      ) : (
        <Box>
          <DialogTitle
            id="form-dialog-title"
            style={{
              textAlign: 'center',
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecoration: 'none',
            }}>
            {t({ defaultMessage: `Can't log in?`, id: 'dvwUVb' })}
          </DialogTitle>
          <DialogContent>
            {!forgotPwdState.isSuccess ? (
              <Box>
                <Typography variant="subtitle1">
                  {t({
                    defaultMessage: `We'll send a recovery link to:`,
                    id: 'JK0QuE',
                  })}
                </Typography>
                <TextField
                  label={t({
                    defaultMessage: 'Email',
                    id: 'sy+pv5',
                  })}
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  style={{ marginBottom: 0 }}
                  type="text"
                  autoComplete="email"
                  fullWidth
                  error={forgotPwdState.errorCode !== null}
                  helperText={getErrorMessage(forgotPwdState.errorCode)}
                  value={forgotPwdState.email}
                  onChange={onEmailChange}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      onEnter();
                    }
                  }}
                />
                <Button
                  className='send-link'
                  fullWidth
                  disableElevation
                  variant={'contained'}
                  color="primary"
                  style={{ paddingBlock: 10 }}
                  onClick={onEnter}>
                  {!forgotPwdState.isSending ? (
                    t({
                      defaultMessage: 'Send recovery link',
                      id: 'AeOnbI',
                    })
                  ) : (
                    <CircularProgress color="info" size={24} />
                  )}
                </Button>
              </Box>
            ) : (
              <EmailSuccessPage email={forgotPwdState.email} />
            )}
          </DialogContent>
          <ReturnToLoginLink />
        </Box>
      )}
    </Dialog>
  );
};
