// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

export enum LanguageSupported {
  'en-AU' = 'English (Australia)',
  'es-CL' = 'Spanish',
  'fr-FR' = 'French',
}

export const DEFAULT_LANGUAGE = 'en-AU';
export const SUPPORTED_LANGUAGES = Object.keys(LanguageSupported);
