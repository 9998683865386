// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { fetchGet, fetchPost } from './httpUtils';

// Get the url of the auth service
export const getAuthServiceUrl = () => {
  return 'https://dev.auth.immt.cloud';
};

// Check if it is an enterprise login with auth service
export const isEnterpriseUser = async (email: string) => {
  const requestURL = `${getAuthServiceUrl()}/is-enterprise`;
  const params = new URLSearchParams({ email });
  return fetchGet(`${requestURL}?${params.toString()}`, { headers: {} }).then((res) => res.isEnterprise);
};

// Redirect to the auth service login endpoint
export const redirectToAuthLogin = (payload?: Record<string, string>) => {
  const params = new URLSearchParams();
  // Add params passed to the login endpoint
  Object.entries(payload ?? {}).forEach(([name, value]) => {
    params.set(name, value);
  });

  const signinAPI = `${getAuthServiceUrl()}/login?${params.toString()}`;
  window.location.href = signinAPI;
};

// Validate user credentials using Cognito
export const loginWithCognito = (
  username: string,
  password: string,
  csrfToken: string,
  redirectTo: string
): Promise<string> => {
  const requestURL = `${getAuthServiceUrl()}/authorize`;
  const body = JSON.stringify({
    username,
    password,
    csrfToken,
    redirectTo,
  });
  return fetchPost(requestURL, { body, headers: {} }).then((res) => res.token);
};

// Redirect users back to the product
export const redirectBackToProduct = (redirectUrl: string, params: Record<string, string>) => {
  // Extract the base and search params from the redirect url
  const [baseUrl, searchParams] = redirectUrl.split('?');
  const newParams = new URLSearchParams(searchParams);

  // Append new params to the redirect url
  Object.entries(params).forEach(([name, value]) => {
    newParams.set(name, value);
  });

  window.location.href = `${baseUrl}?${newParams.toString()}`;
};
