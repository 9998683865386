// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Typography, Button } from '@mui/material';
import { t } from '../../../i18n/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extractDomain } from '../../../utils/httpUtils';

export const AccountDoesNotExist = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const domain = extractDomain(searchParams.get('redirectTo')!);

  // Redirect users back to the login page if they want to switch account
  // If the user session is stored in the future, we'd also log the user out of the current account and clear the cookie.
  const onClickSwitchAccount = () => navigate({ pathname: '/login', search: `?${searchParams.toString()}` });

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {t(
          {
            defaultMessage:
              'You do not have an account in {siteURL} Please contact your OPA Administrator to create an account.',
            id: 'mrok76',
          },
          { siteURL: domain }
        )}
      </Typography>
      <Button variant="contained" color="primary" onClick={onClickSwitchAccount}>
        {t({
          defaultMessage: 'Switch accounts',
          id: '6xNr8c',
        })}
      </Button>
    </div>
  );
};
