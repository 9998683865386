// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { Box, Button, DialogContent, DialogTitle, TextField } from '@mui/material';
import { t } from '../../../i18n/i18n';
import { useLoginFormContext } from '../LoginForm';
import { getErrorMessage } from '../../../utils/errorUtils';

const UsernamePage = () => {
  const { loginState, onUsernameChange, onContinue } = useLoginFormContext();

  return (
    <Box>
      <DialogTitle>
        {t({
          defaultMessage: 'LOGIN',
          id: 'MRBDTz',
        })}
      </DialogTitle>
      <DialogContent>
        <TextField
          label={t({
            defaultMessage: 'Username/Email',
            id: '0wBz4H',
          })}
          variant="outlined"
          autoFocus
          margin="dense"
          style={{ marginBottom: 0 }}
          type="text"
          autoComplete="username"
          fullWidth
          error={loginState.errorCode !== null}
          helperText={getErrorMessage(loginState.errorCode)}
          value={loginState.username}
          onChange={onUsernameChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              onContinue(ev);
            }
          }}
        />

        <Button
          type="submit"
          fullWidth
          onClick={onContinue}
          variant={'contained'}
          disableElevation
          style={{ paddingBlock: 10 }}>
          {t({
            defaultMessage: 'Continue',
            id: 'acrOoz',
          })}
        </Button>
      </DialogContent>
    </Box>
  );
};

export default UsernamePage;
