// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useSearchParams } from 'react-router-dom';
import { t } from '../../../i18n/i18n';
import { useLoginFormContext } from '../LoginForm';
import { getErrorMessage } from '../../../utils/errorUtils';
import { useState } from 'react';

const PasswordPage = () => {
  const { loginState, onSubmit, onPasswordChange, goBack } = useLoginFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <IconButton
        aria-label="back to username page"
        style={{ background: 'none' }}
        onClick={(e) => {
          e.preventDefault();
          goBack();
        }}>
        <ArrowBackIcon style={{ fontSize: '25px' }} />
      </IconButton>
      <DialogTitle>
        <Typography>{loginState.username}</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          label={t({
            defaultMessage: 'Password',
            id: '5sg7KC',
          })}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          fullWidth
          error={loginState.errorCode !== null}
          helperText={getErrorMessage(loginState.errorCode)}
          value={loginState.password}
          onChange={onPasswordChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              onSubmit(ev);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          fullWidth
          onClick={onSubmit}
          variant={'contained'}
          disableElevation
          style={{ paddingBlock: 10 }}>
          {t({
            defaultMessage: 'Sign In',
            id: 'Ub+AGc',
          })}
        </Button>

        <Link to={{ pathname: `/forgot`, search: searchParams.toString() }}>
          <Button type="button" color={'primary'} variant="text" style={{ textTransform: 'none' }}>
            {t({
              defaultMessage: 'Forgot password?',
              id: 'V/JHlm',
            })}
          </Button>
        </Link>
      </DialogContent>
    </Box>
  );
};

export default PasswordPage;
