// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import {
  createContext,
  useContext,
  useState,
  ChangeEventHandler,
  FormEventHandler,
  ChangeEvent,
  FormEvent,
  useEffect,
} from 'react';
import { Box, Dialog, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import UsernamePage from './LoginFormPages/UsernamePage';
import PasswordPage from './LoginFormPages/PasswordPage';
import LoadingPage from '../Common/LoadingPage';
import { changeLanguage, t, Language } from '../../i18n/i18n';
import { ErrorCode, ErrorCodeStrings, getErrorCode } from '../../utils/errorUtils';
import { DEFAULT_LANGUAGE, LanguageSupported, SUPPORTED_LANGUAGES } from '../../i18n/languages';
import {
  isEnterpriseUser,
  redirectBackToProduct,
  redirectToAuthLogin,
  loginWithCognito,
} from '../../utils/authServiceUtils';
import { emailFormatValidation } from '../../utils/emailUtils';
import { useSearchParams } from 'react-router-dom';

export const enum Page {
  Username,
  Password,
}

interface ILoginState {
  username: string;
  password: string;
  redirectTo: string | null;
  csrfToken: string | null;
  errorCode: ErrorCodeStrings | null;
  isLoading: boolean;
}

interface LoginFormContext {
  loginState: ILoginState;
  goBack: () => void;
  onUsernameChange: ChangeEventHandler;
  onPasswordChange: ChangeEventHandler;
  onContinue: FormEventHandler;
  onSubmit: FormEventHandler;
}

const loginFormContext = createContext<LoginFormContext | null>(null);

export const useLoginFormContext = () => {
  const ctx = useContext(loginFormContext);

  if (ctx === null) {
    throw new Error("Could not access the Login Form's context.");
  }

  return ctx;
};

export const LoginForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const csrfToken = searchParams.get('csrfToken');
  const redirectTo = searchParams.get('redirectTo');
  const language = searchParams.get('lang') as Language;

  const [page, setPage] = useState(Page.Username);
  const [lang, setLang] = useState(language ?? DEFAULT_LANGUAGE);

  const [loginState, setLoginState] = useState<ILoginState>({
    username: '',
    password: '',
    redirectTo: null,
    csrfToken: null,
    errorCode: null,
    isLoading: false,
  });

  const languageList = SUPPORTED_LANGUAGES;

  const validateUsername = (): boolean => {
    if (loginState.username === '') {
      setLoginState({
        ...loginState,
        isLoading: false,
        errorCode: ErrorCode.UsernameRequired,
      });
      return false;
    }

    return true;
  };

  const validatePassword = (): boolean => {
    if (loginState.password === '') {
      setLoginState({
        ...loginState,
        isLoading: false,
        errorCode: ErrorCode.PasswordRequired,
      });
      return false;
    }

    return true;
  };

  const setErrorState = (err: any) => {
    console.error(err);
    setLoginState({
      ...loginState,
      isLoading: false,
      errorCode: getErrorCode(err),
    });
  };

  const onContinue = async (event: FormEvent) => {
    event.preventDefault();
    setLoginState({ ...loginState, isLoading: true, errorCode: null });

    try {
      if (validateUsername()) {
        const { username, csrfToken, redirectTo } = loginState;
        if (!csrfToken || !redirectTo) {
          throw Error('Missing parameters.');
        }
        let isEnterprise = false;
        // Only try the enterprise login if the given username is an email
        if (emailFormatValidation.test(username)) {
          // Check if it is an enterprise email
          isEnterprise = await isEnterpriseUser(username);
        }

        // if true, redirect to the login endpoint with csrf token and redirectUrl passed in the params
        if (isEnterprise) {
          redirectToAuthLogin({ email: username, csrfToken, redirectTo });
        } else {
          // if false, go back to local login
          setLoginState({ ...loginState, isLoading: false, errorCode: null });
          setPage(Page.Password);
        }
      }
    } catch (err) {
      // log an error in case the call to the auth service failed due to internet connectivity. This will prompt the user to try again.
      setErrorState(err);
    }
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setLoginState({ ...loginState, isLoading: true, errorCode: null });

    if (validatePassword()) {
      try {
        const { csrfToken, redirectTo } = loginState;
        if (!csrfToken || !redirectTo) {
          throw Error('Missing parameters.');
        }
        // Validate the user credential with Cognito
        const token = await loginWithCognito(loginState.username, loginState.password, csrfToken, redirectTo);
        // If the credential is valid, we will pass the JWT returned from cognito
        redirectBackToProduct(redirectTo, { token });
      } catch (err) {
        // If the validation with Cognito failed
        setErrorState(err);
      }
    }
  };

  const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginState({ ...loginState, username: event.target.value });
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginState({ ...loginState, password: event.target.value.trim() });
  };

  const onLanguageChange = (event: SelectChangeEvent<string>) => {
    try {
      const newLang = event.target.value as Language;
      changeLanguage(newLang);
      setLang(newLang);

      // Also update the search param to reflect on the new language change
      searchParams.set('lang', newLang);
      setSearchParams(searchParams);
    } catch (err) {
      setErrorState(err);
    }
  };

  const goBack = () => {
    setPage(Page.Username);
    setLoginState({
      ...loginState,
      isLoading: false,
      password: '',
      errorCode: null,
    });
  };

  useEffect(() => {
    // Extract crsfToken and redirectTo from the search params
    setLoginState({ ...loginState, redirectTo, csrfToken });
  }, []); // eslint-disable-line

  return (
    <Dialog open>
      {loginState.isLoading ? (
        <LoadingPage />
      ) : (
        <Box>
          <loginFormContext.Provider
            value={{
              loginState,
              goBack,
              onUsernameChange,
              onPasswordChange,
              onContinue,
              onSubmit,
            }}>
            <Box>{page === Page.Username ? <UsernamePage /> : <PasswordPage />}</Box>
          </loginFormContext.Provider>
          <Box>
            <Select
              onChange={onLanguageChange}
              defaultValue={lang}
              value={lang}
              labelId="language-select-label"
              id="language-select"
              style={{
                fontSize: '13px',
                backgroundColor: '#757575',
                color: 'white',
              }}
              label={t({
                defaultMessage: 'Language',
                id: 'y1Z3or',
              })}>
              {languageList.map((list) => (
                <MenuItem key={list} value={list}>
                  {LanguageSupported[list as Language]}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};
